import React, { Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';
import ProfileForm from '../components/profile/ProfileForm';

import SwitchComponents from '../helpers/switchComponents';

import SlidingPane from 'react-sliding-pane';

import { rtDB, auth } from '../services/firebase';

import Loading from '../components/global/Loading';
import DashboardMain from '../components/global/DashboardMain';
import ContactView from '../components/contacts/ContactView';
import Profiles from '../components/profile/Profiles';

import dayjs from 'dayjs';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDashboard: true,
      isProfileDetailsPaneOpen: false,
      activeComponent: 'dashboard-main',
      currentUser: [],
      contactsList: [],
      selectedContactDetail: {},
      isDataLoaded: false,
    };

    this.dashboardAreaRef = React.createRef();
  }

  handleProfileEditButtonClick = (profileId) => {
    const profilesList = this.state.profilesList;
    let profileDetail = profilesList.filter((profileItem) =>
      profileItem.id.includes(profileId)
    );

    profileDetail = profileDetail[0];

    this.setState({ profileDetail, isProfileDetailsPaneOpen: true });
  };

  async componentDidMount() {
    this.addSnaphotListenersToDatabase();
  }

  addSnaphotListenersToDatabase = () => {
    let selectedContactDetail = this.state.selectedContactDetail;
    rtDB.ref('/profiles/').on('value', (snapshot) => {
      let profilesList = [];
      let currentUser = {};
      let contactsList = [];
      //     let currentUserProfileId = '';

      snapshot.forEach((childSnapshot) => {
        var childData = childSnapshot.val();
        childData = {
          ...childData,
          id: childSnapshot.key,
        };

        if (childData.uid === auth().currentUser.uid) {
          currentUser = childData;
          //       currentUserProfileId = childSnapshot.key;
          //       console.log(currentUserProfileId);
        }

        profilesList.push(childData);
      });

      rtDB.ref('/contacts/').on('value', (snapshot) => {
        selectedContactDetail = this.state.selectedContactDetail;
        contactsList = [];
        snapshot.forEach((childSnapshot) => {
          if (childSnapshot && childSnapshot.val()) {
            var childData = childSnapshot.val();
            childData = {
              ...childData,
              id: childSnapshot.key,
              contact_name: childData.first_name + ' ' + childData.last_name,
              selected: false,
              selected_key: '',
            };
            contactsList.push(childData);

            if (selectedContactDetail.id === childData.id) {
              this.setState({ selectedContactDetail: childData });
            }
          }
        });

        rtDB
          .ref('/interactions/')
          .orderByChild('referrer_id')
          .equalTo(currentUser.id)
          .on('value', (snapshot) => {
            let interactionsList = [];

            snapshot.forEach((childSnapshot) => {
              if (childSnapshot && childSnapshot.val()) {
                var childData = childSnapshot.val();
                childData = {
                  ...childData,
                  id: childSnapshot.key,
                };

                let tempInteraction = contactsList.filter((contactItem) =>
                  contactItem.id.includes(childData.contact_id)
                );
                tempInteraction = tempInteraction[0];

                childData['contact_name'] =
                  tempInteraction.first_name + ' ' + tempInteraction.last_name;

                interactionsList.push(childData);
              }
            });

            rtDB
              .ref('/agent_meetings/')
              .orderByChild('date')
              .startAt(dayjs().subtract(1, 'day').format('YYYY-MM-DD'))

              .on('value', (snapshot) => {
                let agentMeetingsList = [];

                snapshot.forEach((childSnapshot) => {
                  if (childSnapshot && childSnapshot.val()) {
                    var childData = childSnapshot.val();
                    childData = {
                      ...childData,
                      id: childSnapshot.key,
                    };

                    agentMeetingsList.push(childData);
                  }
                });
                this.setState({
                  agentMeetingsList,
                  currentUser,
                  profilesList,
                  contactsList,
                  interactionsList,
                  isDataLoaded: true,
                });
              });
          });
      });
    });
  };

  handleContactRowClicked = (contactId) => {
    const contactsList = this.state.contactsList;

    let selectedContactDetail = contactsList.filter((contactItem) =>
      contactItem.id.includes(contactId)
    );

    selectedContactDetail = selectedContactDetail[0];

    this.setState({ selectedContactDetail, activeComponent: 'contact-view' });
  };

  handleBackToDashboardClick = () => {
    this.setState({ activeComponent: 'dashboard-main' });
  };

  handleProfilesButtonClick = () => {
    this.setState({ activeComponent: 'profiles-list' });
  };

  render() {
    let activeComponent = '';
    if (this.state.isDataLoaded) {
      activeComponent = this.state.activeComponent;
    } else {
      activeComponent = 'loading';
    }

    return (
      <div>
        <Header />

        <div className='row mt-1 py-3 px-3'>
          <React.Fragment>
            {/* start dashboard area */}
            <div
              className='col dashboard-section global-background'
              ref={this.dashboardAreaRef}>
              {/* SwitchComponents need more than 1 child element */}
              <SwitchComponents active={activeComponent}>
                <Loading name='loading' />

                <DashboardMain
                  name='dashboard-main'
                  toDoList={this.state.toDoList}
                  onMyProfileDetailsButtonClick={
                    this.handleProfileEditButtonClick
                  }
                  onProfilesButtonClick={this.handleProfilesButtonClick}
                  onContactRowClicked={this.handleContactRowClicked}
                  contactsList={this.state.contactsList}
                  profilesList={this.state.profilesList}
                  currentUser={this.state.currentUser}
                  interactionsList={this.state.interactionsList}
                  agentMeetingsList={this.state.agentMeetingsList}
                />
                <ContactView
                  name='contact-view'
                  onBackToDashboardClicked={this.handleBackToDashboardClick}
                  selectedContactDetail={this.state.selectedContactDetail}
                  currentUser={this.state.currentUser}
                  profilesList={this.state.profilesList}
                  interactionsList={this.state.interactionsList}
                />

                <Profiles
                  name='profiles-list'
                  profilesList={this.state.profilesList}
                  onBackToDashboardClicked={this.handleBackToDashboardClick}
                  onProfileEditButtonClick={this.handleProfileEditButtonClick}
                />
              </SwitchComponents>
            </div>
            {/* end dashboard area */}
            <SlidingPane
              className='profile-details-pane'
              overlayClassName='pane-details-sliding-pane'
              isOpen={this.state.isProfileDetailsPaneOpen}
              title='My Profile Details'
              subtitle=''
              width='85%'
              from='right'
              onRequestClose={() => {
                this.setState({ isProfileDetailsPaneOpen: false });
              }}>
              <ProfileForm
                currentUser={this.state.currentUser}
                profileDetail={this.state.profileDetail}
                currentUserId={auth().currentUser.uid}
                profilesList={this.state.profilesList}
              />
            </SlidingPane>
          </React.Fragment>
        </div>
        <Footer currentUser={this.state.currentUser} />
      </div>
    );
  }
}

export default Dashboard;
