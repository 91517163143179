import React, { Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';
//import logo from '../images/SBELogo300dpi.jpg';
import { Link } from 'react-router-dom';

export default class HomePage extends Component {
  render() {
    return (
      <div className='home'>
        <Header></Header>
        <section>
          <div className='jumbotron jumbotron-fluid py-5 home-background'>
            <div className='container text-center py-5 '>
              {/* <img src={logo} height='120px' alt='Logo' /> */}
              <h1 className='display-10'>
                Welcome to the <br />
                Qoin CRM Referrals Portal
              </h1>
              <p>&nbsp;</p>
              <p className='lead'>
                A great place to refer and track <br />
                your business contacts to apply for
                <br />
                Qoin Merchant and access to Qoin Agent.
              </p>
              <div className='mt-4'>
                <Link className='btn btn-qoin px-5 mr-3' to='/login'>
                  Login to Your Account
                </Link>
                <Link className='btn px-5' to='/signup'>
                  Create New Account
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </div>
    );
  }
}
