import React, { Component } from 'react';

class Footer extends Component {
  async componentDidMount() {}

  render() {
    return (
      <footer className=''>
        <div className='pt-5 container text-center mt-auto'>
          <p>
            {this.props.currentUser ? (
              <React.Fragment>
                <span className='small'>
                  Signed as {this.props.currentUser.display_name} (
                  {this.props.currentUser.email_address})
                </span>
                <br />
              </React.Fragment>
            ) : (
              ''
            )}
            &copy; Brilliant Success Biz {new Date().getFullYear()}.<br />
            <span className='footer-version'>v20210106.1618</span>
            <br />
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
