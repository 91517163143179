import React, { Component } from 'react';
import Interactions from '../interactions/Interactions';
import { Table } from 'react-bootstrap';

import SlidingPane from 'react-sliding-pane';
import ContactDetailForm from './ContactDetailForm';

import dayjs from 'dayjs';

class ContactView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isContactDetailFormOpen: false,
    };
  }

  async componentDidMount() {}

  componentWillUnmount() {
    /*  rtDB.ref('/interactions/').off('value');  */
  }

  handleContactSaveDeleteButtonClick = () => {
    this.setState({ isContactDetailFormOpen: false });
  };

  handleContactEditClick = () => {
    this.setState({
      selectedContactDetail: this.props.selectedContactDetail,
      isNewContact: false,
      isContactDetailFormOpen: true,
    });
  };

  handleBacktoDashboardClick = () => {
    this.props.onBackToDashboardClicked();
  };

  render() {
    const interactionsList = this.props.interactionsList;
    const filteredInteractionsList = interactionsList.filter(
      (interactionItem) =>
        interactionItem.contact_id.includes(this.props.selectedContactDetail.id)
    );

    return (
      <React.Fragment>
        <div
          onClick={this.handleBacktoDashboardClick}
          className='btn btn-qoin btn-small mb-3'>
          Back to Dashboard
        </div>
        <br />
        <div name='contact-view' className='d-flex'>
          <h2>
            Contact View for: {this.props.selectedContactDetail.contact_name}
          </h2>
          <div
            onClick={this.handleContactEditClick}
            className='right-align btn btn-qoin btn-small btn-success mb-2'>
            Edit Contact
          </div>
        </div>
        <div className='table-responsive-sm'>
          <Table className='table d-flex'>
            <tbody>
              <tr className=''>
                <td>Phone:</td>
                <td>{this.props.selectedContactDetail.phone_number}</td>
                <td>Email:</td>
                <td>{this.props.selectedContactDetail.email_address}</td>
              </tr>
              <tr>
                <td>Notes:</td>
                <td colSpan='3'>{this.props.selectedContactDetail.notes}</td>
              </tr>
              <tr>
                <td>Follow Up Date:</td>
                <td>
                  {dayjs(this.props.selectedContactDetail.followup_date).format(
                    'ddd D/M/YYYY'
                  )}
                </td>
                <td>Next Step:</td>
                <td>{this.props.selectedContactDetail.next_step}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <hr />
        <div>
          <Interactions
            interactionsList={filteredInteractionsList}
            selectedContactDetail={this.props.selectedContactDetail}
            profilesList={this.props.profilesList}
            currentUser={this.props.currentUser}
          />
        </div>

        <SlidingPane
          className='contact-detail-pane'
          overlayClassName='pane-contact-sliding-pane'
          isOpen={this.state.isContactDetailFormOpen}
          title='Contact Detail'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isContactDetailFormOpen: false });
          }}>
          <ContactDetailForm
            referrerProfile={this.props.currentUser}
            profilesList={this.props.profilesList}
            selectedContactDetail={this.props.selectedContactDetail}
            isNewContact={this.state.isNewContact}
            onContactSaveDeleteButtonClicked={() =>
              this.handleContactSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </React.Fragment>
    );
  }
}

export default ContactView;
