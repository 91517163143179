import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import InteractionDetailForm from './InteractionDetailForm';

import dayjs from 'dayjs';

class Interactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInteractionFormOpen: false,
      filteredInteractionsList: [],
      interactionsList: [],
    };
  }

  handleAddNewInteractionButton = () => {
    this.setState({
      isNewInteraction: true,
      isInteractionFormOpen: true,
    });
  };

  componentDidMount() {}

  handleInteractionSaveDeleteButtonClick = (value) => {
    this.setState({ isInteractionFormOpen: false });
  };

  render() {
    const columns = [
      {
        dataField: 'date',
        text: 'Date',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'datetime',
        text: 'Entry',
        sort: true,
        isDummyField: true,
        formatter: (cell, row) => {
          return dayjs(row.date + ' ' + row.time).format('dd D/M/YY - h:mm a');
        },
      },
      {
        dataField: 'time',
        text: '',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'interaction_type',
        text: 'Type',
        sort: true,
      },
      {
        dataField: 'notes',
        text: 'Notes',
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'date',
        order: 'desc',
      },
      {
        dataField: 'time',
        order: 'desc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          selectedInteractionDetail: row,
          isNewInteraction: false,
          active: true,
          isInteractionFormOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };
    return (
      <div>
        <div className='d-flex'>
          <h3>Interactions</h3>
          <div
            onClick={() => this.handleAddNewInteractionButton()}
            className='btn btn-outline-dark ml-auto mb-2'>
            <span>Add New Interaction</span>
          </div>
        </div>
        <BootstrapTable
          noDataIndication='There are no interactions to display here at the moment'
          classes='auto-table-layout'
          keyField='id'
          data={this.props.interactionsList}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='interaction-detail-pane'
          overlayClassName='pane-interaction-sliding-pane'
          isOpen={this.state.isInteractionFormOpen}
          title={
            'Interaction Detail for ' +
            this.props.selectedContactDetail.contact_name
          }
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isInteractionFormOpen: false });
          }}>
          <InteractionDetailForm
            selectedContactDetail={this.props.selectedContactDetail}
            currentUser={this.props.currentUser}
            profilesList={this.props.profilesList}
            selectedInteractionDetail={this.state.selectedInteractionDetail}
            isNewInteraction={this.state.isNewInteraction}
            onInteractionSaveDeleteButtonClicked={() =>
              this.handleInteractionSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default Interactions;
