import React, { Component } from 'react';
import { rtDB, auth } from '../../services/firebase';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getFirebaseTimestamp } from '../../helpers/db';

import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

export default class AgentMeetingDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAgentMeetingDetailForm: false,
      isNewAgentMeeting: this.props.isNewAgentMeeting,
      isAgentAdmin: false,
      selectedList: [],
      contactsList: [],
      editMeetingDetails: false,
      openCloseButtonText: 'Open',
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    rtDB
      .ref(
        'agent_meetings/' +
          this.props.selectedAgentMeetingDetail.id +
          '/contacts/'
      )
      .off('value');

    let referrerId = this.state.agentMeetingDetail.referrer_id;
    if (
      this.state.agentMeetingDetail.status === 'Reserved' ||
      this.state.agentMeetingDetail.status === 'Available'
    ) {
      referrerId = 'None';
    }

    let agentMeeting = {
      referrer_id: referrerId,
      date: this.state.agentMeetingDetail.date,
      time: this.state.agentMeetingDetail.time,
      zoom_link: this.state.agentMeetingDetail.zoom_link,
      notes: this.state.agentMeetingDetail.notes,
      status:
        this.state.agentMeetingDetail.status === 'Reserved'
          ? 'Available'
          : this.state.agentMeetingDetail.status,
      deleted: this.state.agentMeetingDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
      updated: getFirebaseTimestamp(),
      updated_by: auth().currentUser.uid,
    };

    if (agentMeeting.deleted) {
      const newRecycledKey = rtDB.ref().child('recycled').push().key;
      await rtDB.ref('recycled/' + newRecycledKey).set(agentMeeting);

      rtDB
        .ref('agent_meetings/' + this.props.selectedAgentMeetingDetail.id)
        .remove();
    } else {
      if (agentMeeting.date.length > 1 && agentMeeting.time.length > 1) {
        if (this.state.isNewAgentMeeting) {
          const newAgentMeetingKey = rtDB.ref().child('agent_meetings').push()
            .key;
          agentMeeting['id'] = newAgentMeetingKey;
          agentMeeting['created'] = getFirebaseTimestamp();
          agentMeeting['created_by'] = auth().referrerProfile.uid;
          await rtDB
            .ref('agent_meetings/' + newAgentMeetingKey)
            .set(agentMeeting);
        } else {
          rtDB
            .ref('agent_meetings/' + this.props.selectedAgentMeetingDetail.id)
            .update(agentMeeting);
        }
      }
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingAgentMeetingDetailForm: true });

    const newAgentMeetingDetail = {
      referrer_id: 'None',
      date: dayjs().format('YYYY-MM-DD'),
      time: dayjs().format('hh:mm'),
      zoom_link: '',
      notes: '',
      status: 'Available',
      deleted: false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (this.props.isNewAgentMeeting) {
      this.setState({ agentMeetingDetail: newAgentMeetingDetail });
    } else {
      this.setState({
        agentMeetingDetail: this.props.selectedAgentMeetingDetail,
      });
    }

    const isAgentAdmin =
      this.props.referrerProfile.role_level === 'admin' ||
      this.props.referrerProfile.role_level === 'agent';
    let agentMeetingDetail =
      this.state.agentMeetingDetail || this.props.selectedAgentMeetingDetail;

    rtDB
      .ref('agent_meetings/' + agentMeetingDetail.id + '/contacts/')
      .on('value', (snapshot) => {
        let selectedList = [];

        snapshot.forEach((childSnapshot) => {
          if (childSnapshot && childSnapshot.val()) {
            var childData = childSnapshot.val();
            childData = {
              ...childData,
              id: childSnapshot.key,
            };

            selectedList.push(childData);
          }
        });

        if (selectedList.length > 0) {
          agentMeetingDetail['status'] = 'Requested';
        } else {
          if (agentMeetingDetail.status === 'Requested') {
            agentMeetingDetail['status'] = 'Available';
          }
        }

        this.setState({ agentMeetingDetail, selectedList });
      });

    this.setState({ isAgentAdmin, loadingAgentMeetingDetailForm: false });
  }

  handleReserveButtonClick = () => {
    rtDB
      .ref('agent_meetings/' + this.props.selectedAgentMeetingDetail.id)
      .update({
        status: 'Reserved',
      });

    let agentMeetingDetail = this.state.agentMeetingDetail;
    agentMeetingDetail['status'] = 'Reserved';
    agentMeetingDetail['referrer_id'] = this.props.referrerProfile.id;
    this.setState({ agentMeetingDetail });
  };

  handleClearReservationButtonClick = () => {
    rtDB
      .ref('agent_meetings/' + this.props.selectedAgentMeetingDetail.id)
      .update({
        status: 'Available',
      });
    rtDB
      .ref(
        'agent_meetings/' +
          this.props.selectedAgentMeetingDetail.id +
          '/contacts/'
      )
      .remove();
    let agentMeetingDetail = this.state.agentMeetingDetail;
    agentMeetingDetail['status'] = 'Available';

    this.setState({ agentMeetingDetail });
  };

  handleAgentMeetingDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let agentMeetingDetail = { ...this.state.agentMeetingDetail };

    if (target.type === 'checkbox') {
      agentMeetingDetail[name] = target.checked;
    } else {
      agentMeetingDetail[name] = value;
    }
    this.setState({ agentMeetingDetail });
  };

  handleAgentMeetingSaveButton = () => {
    this.props.onAgentMeetingSaveDeleteButtonClicked(true);
  };

  handleAgentMeetingDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this meeting?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let agentMeetingDetail = { ...this.state.agentMeetingDetail };
            agentMeetingDetail['deleted'] = true;
            agentMeetingDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ agentMeetingDetail });
            this.props.onAgentMeetingSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  handleContactsRowClick = (row) => {
    const agentMeetingDetail = this.state.agentMeetingDetail;

    if (
      agentMeetingDetail.status === 'Reserved' ||
      agentMeetingDetail.status === 'Requested'
    ) {
      const selectedContact = {
        contact_id: row.id,
      };
      let dbREF = rtDB.ref(
        'agent_meetings/' +
          this.props.selectedAgentMeetingDetail.id +
          '/contacts/'
      );

      if (row.selected) {
        dbREF.child(row.selected_key).remove();
      } else {
        dbREF.push(selectedContact);
      }
    }
  };

  handleOpenCloseEditFormToggle = () => {
    let editMeetingDetails = this.state.editMeetingDetails;
    editMeetingDetails = !editMeetingDetails;

    let openCloseButtonText = editMeetingDetails ? 'Close' : 'Open';

    this.setState({ editMeetingDetails, openCloseButtonText });
  };

  render() {
    const agentMeetingDetail = this.state.agentMeetingDetail || {};

    let contactsList = this.props.contactsList;
    const selectedList = this.state.selectedList;

    contactsList.forEach((contactItem, index) => {
      let filteredSelectedList = selectedList.find(
        (item) => item.contact_id === contactItem.id
      );

      if (filteredSelectedList) {
        contactsList[index].selected = true;
        contactsList[index].selected_key = filteredSelectedList.id;
      } else {
        contactsList[index].selected = false;
        contactsList[index].selected_key = '';
      }
    });

    let filteredContactList = contactsList.filter(
      (contactItem) => contactItem.referrer_id === this.props.referrerProfile.id
    );

    filteredContactList = filteredContactList.filter(
      (contactItem) =>
        contactItem.status.includes('Introduce to Agent') ||
        contactItem.selected
    );

    const columns = [
      {
        dataField: 'selected',
        text: 'Selected',
        sort: true,
        style: { cursor: 'pointer' },
        formatter: (cell, row) => {
          return cell ? 'Yes' : 'No';
        },
        headerAttrs: {
          hidden: true,
        },
      },
      {
        dataField: 'contact_name',
        text: 'Name',
        sort: true,
        style: { cursor: 'pointer' },
        headerAttrs: {
          hidden: true,
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: 'contact_name',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleContactsRowClick(row);
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    const rowClasses = (row, rowIndex) => {
      if (row.selected) {
        return 'selected-contact-row-class';
      }
      return '';
    };

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingAgentMeetingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}
          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
              {
                this.props.referrerProfile.role_level === 'admin' ||
                this.props.referrerProfile.role_level === 'agent' ? (
                  <div
                    onClick={this.handleOpenCloseEditFormToggle}
                    className='small btn btn-success m-3'>
                    {this.state.openCloseButtonText} Edit Form
                  </div>
                ) : (
                  ''
                ) /* end is admin or agent  */
              }
            </div>
            <React.Fragment>
              {/* Referrer Form START   */}
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <Table borderless className='table d-flex'>
                  <tbody>
                    {this.state.editMeetingDetails ? (
                      <React.Fragment>
                        <tr className=''>
                          <td>Meeting:</td>
                          <td>
                            <input
                              className='form-control'
                              placeholder='Date'
                              name='date'
                              type='date'
                              onChange={this.handleAgentMeetingDetailFormChange}
                              value={agentMeetingDetail.date || ''}></input>
                            <input
                              className='form-control w-100'
                              placeholder='Time'
                              name='time'
                              type='time'
                              onChange={this.handleAgentMeetingDetailFormChange}
                              value={agentMeetingDetail.time || ''}></input>
                          </td>
                          <td>Status:</td>
                          <td>
                            <select
                              className='form-control'
                              name='status'
                              onChange={this.handleAgentMeetingDetailFormChange}
                              value={agentMeetingDetail.status || 'Available'}>
                              <option value='Available'>Available</option>
                              <option value='Reserved'>Reserved</option>
                              <option value='Requested'>Requested</option>
                              <option value='Confirmed'>Confirmed</option>
                            </select>
                          </td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Zoom Link:</td>
                          <td colSpan='4'>
                            <input
                              className='form-control'
                              placeholder='Zoom Link'
                              name='zoom_link'
                              type='text'
                              onChange={this.handleAgentMeetingDetailFormChange}
                              value={
                                agentMeetingDetail.zoom_link || ''
                              }></input>
                          </td>
                        </tr>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <tr className=''>
                          <td>Meeting:</td>
                          <td>
                            <strong>
                              {dayjs(
                                agentMeetingDetail.date +
                                  ' ' +
                                  agentMeetingDetail.time
                              ).format('dddd D/M/YYYY h:mm a')}{' '}
                              (QLD)
                            </strong>
                          </td>
                          <td>Status:</td>
                          <td>{agentMeetingDetail.status}</td>
                          <td>
                            {agentMeetingDetail.status === 'Available' ? (
                              <div
                                onClick={this.handleReserveButtonClick}
                                className='small btn btn-sml btn-warning'>
                                Reserve
                              </div>
                            ) : (
                              ''
                            )}
                            {agentMeetingDetail.status === 'Reserved' ||
                            agentMeetingDetail.status === 'Requested' ? (
                              <div
                                onClick={this.handleClearReservationButtonClick}
                                className='small btn btn-sml btn-warning'>
                                Clear Reservation/Request
                              </div>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Zoom Link:</td>
                          <td colSpan='4'>
                            {agentMeetingDetail.zoom_link ||
                              'No zoom link provided yet'}
                          </td>
                        </tr>
                      </React.Fragment>
                    )}

                    <tr>
                      <td>Referrer:</td>
                      <td colSpan='4'>
                        <div className='form-group'>
                          <select
                            className='form-control'
                            name='referrer_id'
                            onChange={this.handleAgentMeetingDetailFormChange}
                            value={
                              agentMeetingDetail.referrer_id
                                ? agentMeetingDetail.referrer_id
                                : this.props.referrerProfile.id
                            }>
                            <option vale='None'>None</option>
                            {this.props.profilesList.map((item, key) => {
                              return (
                                <option key={key} value={item.id}>
                                  {item.display_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Notes:</td>
                      <td colSpan='4'>
                        <div className='form-group w-100'>
                          <textarea
                            className='form-control'
                            placeholder='Notes'
                            name='notes'
                            onChange={this.handleAgentMeetingDetailFormChange}
                            value={agentMeetingDetail.notes || ''}></textarea>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <hr />
                <h4>Add contacts:</h4>
                <BootstrapTable
                  noDataIndication='There are no contacts to display here at the moment'
                  classes='auto-table-layout'
                  keyField='id'
                  data={filteredContactList}
                  columns={columns}
                  hover
                  condensed
                  bordered={false}
                  defaultSorted={defaultSorted}
                  rowEvents={rowEvents}
                  rowClasses={rowClasses}
                />
                <span className='small'>
                  Click "Reserve" to start adding someone to a meeting. The
                  meeting will automatically change to "Requested" once there is
                  one or more people added to this meeting.
                </span>
                <br />
                <div
                  onClick={this.handleAgentMeetingSaveButton}
                  className='small btn btn-success m-3'>
                  Save Changes
                </div>
                {
                  !this.props.isNewAgentMeeting ? (
                    <div
                      onClick={this.handleAgentMeetingDeleteButton}
                      className='small btn btn-danger m-3'>
                      Delete this Agent Meeting
                    </div>
                  ) : (
                    ''
                  ) /* end isNewAgentMeeting  */
                }
              </form>{' '}
            </React.Fragment>

            {/* Referrer Form END   */}
            <div className='form-group'>
              {this.state.error ? (
                <p className='text-danger'>{this.state.error}</p>
              ) : null}
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
