import React, { Component } from 'react';
import { rtDB, auth } from '../../services/firebase';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getFirebaseTimestamp } from '../../helpers/db';

export default class ContactDetailform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingContactDetailform: false,
      isNewContact: this.props.isNewContact,
      contactDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let contactData = {
      referrer_id: this.props.referrerProfile.id,
      first_name: this.state.contactDetail.first_name,
      last_name: this.state.contactDetail.last_name,
      phone_number: this.state.contactDetail.phone_number,
      email_address: this.state.contactDetail.email_address,
      notes: this.state.contactDetail.notes,
      status: this.state.contactDetail.status,
      followup_date: this.state.contactDetail.followup_date,
      next_step: this.state.contactDetail.next_step,
      deleted: this.state.contactDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
      updated: getFirebaseTimestamp(),
      updated_by: auth().currentUser.uid,
    };

    if (contactData.deleted) {
      const newRecycledKey = rtDB.ref().child('recycled').push().key;
      await rtDB.ref('recycled/' + newRecycledKey).set(contactData);

      rtDB.ref('contacts/' + this.props.selectedContactDetail.id).remove();
    } else {
      if (
        contactData.first_name.length > 1 &&
        contactData.last_name.length > 1
      ) {
        if (this.state.isNewContact) {
          const newContactKey = rtDB.ref().child('contacts').push().key;
          contactData['id'] = newContactKey;
          contactData['created'] = getFirebaseTimestamp();
          contactData['created_by'] = auth().currentUser.uid;
          await rtDB.ref('contacts/' + newContactKey).set(contactData);
        } else {
          rtDB
            .ref('contacts/' + this.props.selectedContactDetail.id)
            .update(contactData);
        }
      }
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingContactDetailform: true });

    const newContactDetail = {
      referrer_id: this.props.referrerProfile.id,
      first_name: '',
      last_name: '',
      phone_number: '',
      email_address: '',
      notes: '',
      status: 'Prospect',
      followup_date: '',
      next_step: '',
      deleted: false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (this.props.isNewContact) {
      this.setState({ contactDetail: newContactDetail });
    } else {
      this.setState({ contactDetail: this.props.selectedContactDetail });
    }

    this.setState({ loadingContactDetailform: false });
  }

  handleContactDetailformChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let contactDetail = { ...this.state.contactDetail };

    if (target.type === 'checkbox') {
      contactDetail[name] = target.checked;
    } else {
      contactDetail[name] = value;
    }
    this.setState({ contactDetail });
  };

  handleContactSaveButton = () => {
    this.props.onContactSaveDeleteButtonClicked(true);
  };

  handleContactDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this contact?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let contactDetail = { ...this.state.contactDetail };
            contactDetail['deleted'] = true;
            contactDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ contactDetail });
            this.props.onContactSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const contactDetail = this.state.contactDetail;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingContactDetailform ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='d-flex'>
                <div className='form-group'>
                  <label className='form_label' htmlFor='referrer_id'>
                    Referrer:
                  </label>
                  <select
                    className='form-control'
                    name='referrer_id'
                    onChange={this.handleContactDetailformChange}
                    value={
                      contactDetail.referrer_id
                        ? contactDetail.referrer_id
                        : this.props.referrerProfile.id
                    }>
                    {this.props.profilesList.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.display_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='status'>
                    Status:
                  </label>
                  <select
                    className='form-control'
                    name='status'
                    onChange={this.handleContactDetailformChange}
                    value={contactDetail.status || 'Prospect'}>
                    <option value='Prospect'>Prospect</option>
                    <option value='Initial Qoin Discussed'>
                      Initial Qoin Discussed
                    </option>
                    <option value='Not Interested'>Not Interested</option>
                    <option value='Introduce to Agent'>
                      Introduce to Agent
                    </option>
                    <option value='Meeting Booked'>Meeting Booked</option>
                    <option value='Onboard Complete'>Onboard Complete</option>
                    <option value='Not Proceeding'>
                      Not Proceeding after Onboard
                    </option>
                  </select>
                </div>
              </div>

              <div className='d-flex'>
                <div className='form-group'>
                  <label className='form_label' htmlFor='first_name'>
                    First Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='First Name'
                    name='first_name'
                    type='text'
                    onChange={this.handleContactDetailformChange}
                    value={contactDetail.first_name || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='last_name'>
                    Last Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Last Name'
                    name='last_name'
                    type='text'
                    onChange={this.handleContactDetailformChange}
                    value={contactDetail.last_name || ''}></input>
                </div>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='phone_number'>
                  Phone Number
                </label>
                <input
                  className='form-control'
                  placeholder='Phone Number'
                  name='phone_number'
                  type='text'
                  onChange={this.handleContactDetailformChange}
                  value={contactDetail.phone_number || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='email_address'>
                  Email Address
                </label>
                <input
                  className='form-control'
                  placeholder='Email Address'
                  name='email_address'
                  type='text'
                  onChange={this.handleContactDetailformChange}
                  value={contactDetail.email_address || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='notes'>
                  Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Notes'
                  name='notes'
                  onChange={this.handleContactDetailformChange}
                  value={contactDetail.notes || ''}></textarea>
              </div>

              <div className='form-group w-50'>
                <label className='form_label' htmlFor='followup_date'>
                  Followup Date
                </label>
                <input
                  className='form-control'
                  placeholder='Followup Date'
                  name='followup_date'
                  type='date'
                  onChange={this.handleContactDetailformChange}
                  value={contactDetail.followup_date || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='next_step'>
                  Next Step
                </label>
                <input
                  className='form-control'
                  placeholder='Next Step'
                  name='next_step'
                  type='text'
                  onChange={this.handleContactDetailformChange}
                  value={contactDetail.next_step || ''}></input>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleContactSaveButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>
            {!this.props.isNewContact ? (
              <div
                onClick={this.handleContactDeleteButton}
                className='small btn btn-danger'>
                Delete this Contact
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
