import React, { Component } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import Login from './pages/Login';
import { auth, rtDB } from './services/firebase';
import './styles.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { userProfile } from './helpers/dataObjects';

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  let locationPath = useLocation();

  let locationPathname = '/dashboard';
  if (locationPath.state) {
    locationPathname = locationPath.state.from.pathname;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={locationPathname} />
        )
      }
    />
  );
}

async function createNewProfileId(user) {
  /* create new profile  */
  const newProfile = {
    ...userProfile,
    uid: user.uid ? user.uid : '',
    display_name: user.displayName ? user.displayName : '',
    email_address: user.email ? user.email : '',
    mobile_phone: user.phoneNumber ? user.phoneNUmber : '',
    email_verified: user.emailVerified ? user.emailVerified : '',
  };

  const newProfileKey = rtDB.ref().child('profiles').push().key;
  newProfile['id'] = newProfileKey;

  await rtDB.ref('profiles/' + newProfileKey).set(newProfile);

  return newProfileKey;
}

async function getProfileIdForCurrentUser(user) {
  let response = {
    profile_id: 'new',
    user_id: user.uid,
  };

  await rtDB
    .ref('/profiles')
    .orderByChild('uid')
    .equalTo(user.uid)
    .once('value')
    .then(function (snapshot) {
      if (snapshot.val()) {
        response['profile_id'] = Object.keys(snapshot.val());
      }
    });
  return response;
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
      loadingModule: 'App',
    };
  }

  async componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        const loadUserProfile = async (user) => {
          let profileId = await getProfileIdForCurrentUser(user);
          if (profileId.profile_id === 'new') {
            /* create new profile */
            profileId.profile_id = await createNewProfileId(user);
          }
        };
        loadUserProfile(user).then(() => {
          this.setState({ authenticated: true, loading: false });
        });
      } else {
        this.setState({ authenticated: false, loading: false });
      }
    });
  }

  render() {
    return this.state.loading === true ? (
      <React.Fragment>
        <div className='loading-text'>
          {this.state.loadingModule} Loading...
        </div>
        <div
          className='spinner-border text-success loading-indicator'
          role='status'>
          <span className='sr-only'>Loading... {this.state.loadingModule}</span>
        </div>
      </React.Fragment>
    ) : (
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <PrivateRoute
            path='/dashboard'
            authenticated={this.state.authenticated}
            component={Dashboard}
            currentUser={this.state.currentUser}
            profilesList={this.state.profilesList}
            contactsList={this.state.contactsList}
          />

          <PublicRoute
            path='/signup'
            authenticated={this.state.authenticated}
            component={Signup}
          />
          <PublicRoute
            path='/login'
            authenticated={this.state.authenticated}
            component={Login}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
