import { rtDB } from '../services/firebase';

export function readChats() {
  let abc = [];
  rtDB.ref('chats').on('value', (snapshot) => {
    snapshot.forEach((snap) => {
      abc.push(snap.val());
    });
    return abc;
  });
}

export function writeChats(message) {
  return rtDB.ref('chats').push({
    content: message.content,
    timestamp: message.timestamp,
    uid: message.uid,
  });
}

export function readProfiles() {
  let abc = [];
  rtDB.ref('profiles').on('value', (snapshot) => {
    snapshot.forEach((snap) => {
      abc.push(snap.val());
    });
    return abc;
  });
}

export function writeProfile(profile) {
  return rtDB.ref('profiles').push({
    first_name: profile.first_name,
    uid: profile.uid,
  });
}

export function getFirestoreTimestamp() {
  return rtDB.md.firebase_.firebase.Timestamp.now();
}

export function getFirebaseTimestamp() {
  return rtDB.app.firebase_.firestore.Timestamp.now();
}
