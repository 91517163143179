import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

export default class Profiles extends Component {
  handleBacktoDashboardClick = () => {
    this.props.onBackToDashboardClicked();
  };

  render() {
    const columns = [
      {
        dataField: 'display_name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'mobile_phone',
        text: 'Mobile Phone',
        sort: true,
      },
      {
        dataField: 'email_address',
        text: 'Email',
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'display_name',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.onProfileEditButtonClick(row.id);
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    let profilesList = this.props.profilesList;

    return (
      <React.Fragment>
        <div
          onClick={this.handleBacktoDashboardClick}
          className='btn btn-qoin btn-small mb-3'>
          Back to Dashboard
        </div>
        <br />
        <div className='profiles-area' ref={this.myRef}>
          <BootstrapTable
            noDataIndication='There are no profiles to display here at the moment'
            classes='auto-table-layout'
            keyField='id'
            data={profilesList || []}
            columns={columns}
            striped
            hover
            condensed
            bordered={false}
            defaultSorted={defaultSorted}
            rowEvents={rowEvents}
          />

          <div className='small mt-3 text-justify'>
            View more details for each contact or edit your own profile by
            selecting profile above
          </div>
        </div>
      </React.Fragment>
    );
  }
}
