import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import InteractionDetailForm from '../interactions/InteractionDetailForm';

import dayjs from 'dayjs';

class ToDoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInteractionFormOpen: false,
      filteredToDoListList: [],
      interactionsList: [],
    };
  }

  handleAddNewToDoButton = () => {
    this.setState({
      isNewInteraction: true,
      isInteractionFormOpen: true,
    });
  };

  componentDidMount() {}

  handleInteractionSaveDeleteButtonClick = (value) => {
    this.setState({ isInteractionFormOpen: false });
  };

  render() {
    const columns = [
      {
        dataField: 'contact_name',
        text: 'Contact',
        sort: true,
      },
      {
        dataField: 'followup_date',
        text: 'Follow Up By',
        sort: true,
        formatter: (cell) => {
          return dayjs(cell).format('D/M/YYYY');
        },
      },
      {
        dataField: 'next_step',
        text: 'Next Step',
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'followup_date',
        order: 'desc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        let selectedContactDetail = this.props.contactsList.filter(
          (contactItem) => contactItem.id.includes(row.contact_id)
        );
        selectedContactDetail = selectedContactDetail[0];

        this.setState({
          selectedContactDetail,
          selectedInteractionDetail: row,
          isNewInteraction: false,
          active: true,
          isInteractionFormOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    const interactionsList = this.props.interactionsList || [];

    const filteredToDoList = interactionsList.filter((interactionItem) =>
      interactionItem.status.includes('To Do')
    );

    return (
      <div>
        <div className='d-flex mt-3'>
          <h3>My To Do List</h3>
          {/* <div
            onClick={() => this.handleAddNewToDoButton()}
            className='btn btn-outline-dark ml-auto'>
            <span>Add New ToDo</span>
         </div>  */}
        </div>
        <BootstrapTable
          noDataIndication='There are no interactions to display here at the moment'
          classes='auto-table-layout'
          keyField='id'
          data={filteredToDoList}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='interaction-detail-pane'
          overlayClassName='pane-interaction-sliding-pane'
          isOpen={this.state.isInteractionFormOpen}
          title='To Do Detail'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isInteractionFormOpen: false });
          }}>
          <InteractionDetailForm
            selectedContactDetail={this.state.selectedContactDetail}
            currentUser={this.props.currentUser}
            profilesList={this.props.profilesList}
            selectedInteractionDetail={this.state.selectedInteractionDetail}
            isNewInteraction={this.state.isNewInteraction}
            onInteractionSaveDeleteButtonClicked={() =>
              this.handleInteractionSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default ToDoList;
