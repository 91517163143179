import React, { Component } from 'react';

import Contacts from '../contacts/Contacts';
import SlidingPane from 'react-sliding-pane';
import ToDoList from '../todolist/ToDoList';
import AgentMeetings from '../agent-meetings/AgentMeetings';

class DashboardMain extends Component {
  async componentDidMount() {}

  handleContactRowClicked = (contactId) => {
    this.props.onContactRowClicked(contactId);
  };

  handleMyProfileDetailsButtonClick = () => {};

  render() {
    return (
      <React.Fragment>
        <div name='main-dashboard' className='d-flex'>
          <h2> Welcome {this.props.currentUser.display_name}</h2>

          <div
            onClick={() =>
              this.props.onMyProfileDetailsButtonClick(
                this.props.currentUser.id
              )
            }
            className='right-align btn btn-qoin btn-small btn-success mb-2'>
            Edit My Profile
          </div>
          {this.props.currentUser.role_level === 'agent' ||
          this.props.currentUser.role_level === 'admin' ? (
            <div
              onClick={this.props.onProfilesButtonClick}
              className='btn btn-qoin btn-small btn-success mb-2 ml-2'>
              Profiles List
            </div>
          ) : (
            ''
          )}
        </div>
        <hr />
        <div className='row'>
          <div className='col-sm-8'>
            <div>
              <ToDoList
                contactsList={this.props.contactsList}
                currentUser={this.props.currentUser}
                profilesList={this.props.profilesList}
                interactionsList={this.props.interactionsList}
              />
            </div>
            <hr />
            <div>
              <Contacts
                onContactRowClicked={this.handleContactRowClicked}
                contactsList={this.props.contactsList}
                profilesList={this.props.profilesList}
                currentUser={this.props.currentUser}
              />
            </div>
          </div>
          <div className='col-sm-4'>
            <AgentMeetings
              contactsList={this.props.contactsList}
              profilesList={this.props.profilesList}
              currentUser={this.props.currentUser}
              agentMeetingsList={this.props.agentMeetingsList}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardMain;
