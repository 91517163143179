import firebase from 'firebase';

var firebaseConfig = {
  apiKey: 'AIzaSyBoIRWaPZfhehApH3nSXJKg9EZIDBhdDP4',
  authDomain: 'qoin-crm.firebaseapp.com',
  databaseURL:
    'https://qoin-crm-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'qoin-crm',
  storageBucket: 'qoin-crm.appspot.com',
  messagingSenderId: '29644998152',
  appId: '1:29644998152:web:39d48d81c206efa5a573bd',
  measurementId: 'G-ZKYFP5329N',
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const auth = firebase.auth;
export const fsDB = firebase.firestore();
export const rtDB = firebase.database();
