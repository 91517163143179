import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import AgentMeetingDetailForm from './AgentMeetingDetailForm';

import dayjs from 'dayjs';

class AgentMeetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgentMeetingDetailFormOpen: false,
      contactsList: this.props.contactsList,
      agentMeetingsList: [],
      filteredAgentMeetingsList: [],
      isReferrerColHidden: true,
    };
  }

  handleAddNewAgentMeetingButton = () => {
    this.setState({
      isNewAgentMeeting: true,
      isAgentMeetingDetailFormOpen: true,
    });
  };

  handleAgentMeetingRowClicked = (agentMeetingId) => {
    this.props.onAgentMeetingRowClicked(agentMeetingId);
  };

  componentDidMount() {}

  handleAgentMeetingSaveDeleteButtonClick = (value) => {
    this.setState({ isAgentMeetingDetailFormOpen: false });
  };

  render() {
    let contactsList = this.props.contactsList;

    const contactsFilteredByCurrentUser = contactsList.filter((contactItem) =>
      contactItem.referrer_id.includes(this.props.currentUser.id)
    );

    const columns = [
      {
        dataField: 'date',
        text: 'Date',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'datetime',
        text: 'Date/Time',
        sort: true,
        isDummyField: true,
        formatter: (cell, row) => {
          return dayjs(row.date + ' ' + row.time).format('dd D/M - h:mm a');
        },
      },
      {
        dataField: 'time',
        text: '',
        sort: true,
        hidden: true,
      },

      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'date',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          selectedAgentMeetingDetail: row,
          isNewAgentMeeting: false,
          active: true,
          isAgentMeetingDetailFormOpen: true,
        });
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    const agentMeetingsList = this.props.agentMeetingsList;

    let filteredAgentMeetingsList = agentMeetingsList.filter(
      (meetingItem) =>
        meetingItem.referrer_id === this.props.currentUser.id ||
        meetingItem.status === 'Available' ||
        meetingItem.status === 'Reserved'
    );

    return (
      <div>
        <div className='d-flex  mt-3'>
          <h3>Agent Meetings</h3>
          {this.props.currentUser.role_level === 'agent' ||
          this.props.currentUser.role_level === 'admin' ? (
            <div
              onClick={() => this.handleAddNewAgentMeetingButton()}
              className='btn btn-outline-dark ml-auto mb-2'>
              <span>Add New Meeting</span>
            </div>
          ) : (
            ''
          )}
        </div>
        <BootstrapTable
          noDataIndication='There are no meetings to display here at the moment'
          classes='auto-table-layout'
          keyField='id'
          data={filteredAgentMeetingsList || []}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <SlidingPane
          className='agent-meeting-detail-pane'
          overlayClassName='pane-agent-meeting-sliding-pane'
          isOpen={this.state.isAgentMeetingDetailFormOpen}
          title='Agent Meeting Detail'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isAgentMeetingDetailFormOpen: false });
          }}>
          <AgentMeetingDetailForm
            referrerProfile={this.props.currentUser}
            contactsList={this.props.contactsList}
            profilesList={this.props.profilesList}
            selectedAgentMeetingDetail={this.state.selectedAgentMeetingDetail}
            isNewAgentMeeting={this.state.isNewAgentMeeting}
            onAgentMeetingSaveDeleteButtonClicked={() =>
              this.handleAgentMeetingSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default AgentMeetings;
