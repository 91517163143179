import React, { Component } from 'react';
import { rtDB, auth } from '../../services/firebase';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getFirebaseTimestamp } from '../../helpers/db';

export default class InteractionDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingInteractionDetailForm: false,
      isNewInteraction: this.props.isNewInteraction,
      interactionDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let interactionData = {
      referrer_id: this.state.interactionDetail.referrer_id,
      contact_id: this.props.selectedContactDetail.id,
      interaction_type: this.state.interactionDetail.interaction_type,
      date: this.state.interactionDetail.date,
      time: this.state.interactionDetail.time,
      notes: this.state.interactionDetail.notes,
      todo_list: this.state.interactionDetail.todo_list,
      status: this.state.interactionDetail.status,
      followup_date: this.state.interactionDetail.followup_date,
      next_step: this.state.interactionDetail.next_step,
      deleted: this.state.interactionDetail.deleted,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
      updated: getFirebaseTimestamp(),
      updated_by: auth().currentUser.uid,
    };

    if (interactionData.deleted) {
      await rtDB
        .ref('recycled/interactions/' + this.props.selectedInteractionDetail.id)
        .set(interactionData);

      rtDB
        .ref('interactions/' + this.props.selectedInteractionDetail.id)
        .remove();
    } else {
      if (
        interactionData.date.length > 1 &&
        interactionData.time.length > 1 &&
        interactionData.notes.length > 1
      ) {
        if (interactionData.status === 'to_do_list') {
          rtDB.ref('contacts/' + this.props.selectedContactDetail.id).update({
            followup_date: this.state.interactionDetail.followup_date,
            next_step: this.state.interactionDetail.next_step,
            updated: getFirebaseTimestamp(),
            updated_by: auth().currentUser.uid,
          });
        }

        if (this.state.isNewInteraction) {
          const newInteractionKey = rtDB.ref().child('interactions').push().key;
          interactionData['id'] = newInteractionKey;
          interactionData['created'] = getFirebaseTimestamp();
          interactionData['created_by'] = auth().currentUser.uid;
          interactionData['status'] = 'new';

          await rtDB
            .ref('interactions/' + newInteractionKey)
            .set(interactionData);
        } else {
          rtDB
            .ref('interactions/' + this.props.selectedInteractionDetail.id)
            .update(interactionData);
        }
      }
    }
  }

  async componentDidMount() {
    this.setState({
      readError: null,
      loadingInteractionDetailForm: true,
    });

    const newInteractionDetail = {
      referrer_id: this.props.currentUser.id,
      interaction_type: 'Phone',
      date: dayjs().format('YYYY-MM-DD'),
      time: dayjs().format('HH:mm'),
      todo_list: '',
      notes: '',
      status: 'Interaction',
      followup_date: '',
      next_step: '',
      deleted: false,
      purge_date: dayjs().add(90, 'day').format('YYYY-MM-DD'),
    };

    if (this.props.isNewInteraction) {
      this.setState({ interactionDetail: newInteractionDetail });
    } else {
      this.setState({
        interactionDetail: this.props.selectedInteractionDetail,
      });
    }

    this.setState({ loadingInteractionDetailForm: false });
  }

  handleInteractionDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let interactionDetail = { ...this.state.interactionDetail };

    if (target.type === 'checkbox') {
      interactionDetail[name] = target.checked;
    } else {
      interactionDetail[name] = value;
    }
    this.setState({ interactionDetail });
  };

  handleInteractionSaveButton = () => {
    this.props.onInteractionSaveDeleteButtonClicked(true);
  };

  handleInteractionDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this interaction?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let interactionDetail = { ...this.state.interactionDetail };
            interactionDetail['deleted'] = true;
            interactionDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ interactionDetail });
            this.props.onInteractionSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const interactionDetail = this.state.interactionDetail;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingInteractionDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='d-flex'>
                <div className='form-group'>
                  <label className='form_label' htmlFor='referrer_id'>
                    Referrer:
                  </label>
                  <select
                    className='form-control'
                    name='referrer_id'
                    onChange={this.handleInteractionDetailFormChange}
                    value={
                      interactionDetail.referrer_id
                        ? interactionDetail.referrer_id
                        : this.props.currentUser.id
                    }>
                    {this.props.profilesList.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.display_name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='status'>
                    Status:
                  </label>
                  <select
                    className='form-control'
                    name='status'
                    onChange={this.handleInteractionDetailFormChange}
                    value={interactionDetail.status || 'Interaction'}>
                    <option value='Interaction'>Interaction</option>
                    <option value='To Do'>To Do List</option>
                    <option value='Closed'>Closed</option>
                    <option value='Completed'>Completed</option>
                  </select>
                </div>
              </div>

              <div className='d-flex'>
                <div className='form-group'>
                  <label className='form_label' htmlFor='interaction_type'>
                    Type:
                  </label>
                  <select
                    className='form-control'
                    name='interaction_type'
                    onChange={this.handleInteractionDetailFormChange}
                    value={interactionDetail.interaction_type || 'Prospect'}>
                    <option value='Phone'>Phone call</option>
                    <option value='Email'>Email</option>
                    <option value='Message'>SMS/Messenger/etc</option>
                    <option value='Face2Face'>Face2Facee</option>
                    <option value='Meeting'>Meeting</option>
                    <option value='Other'>Other</option>
                  </select>
                </div>

                <div className='form-group w-50'>
                  <label className='form_label' htmlFor='date'>
                    Interaction Date
                  </label>
                  <input
                    className='form-control'
                    placeholder='Interaction Date'
                    name='date'
                    type='date'
                    onChange={this.handleInteractionDetailFormChange}
                    value={interactionDetail.date || ''}></input>
                </div>

                <div className='form-group w-50'>
                  <label className='form_label' htmlFor='date'>
                    Interaction Time
                  </label>
                  <input
                    className='form-control'
                    placeholder='Interaction Time'
                    name='time'
                    type='time'
                    onChange={this.handleInteractionDetailFormChange}
                    value={interactionDetail.time || ''}></input>
                </div>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='notes'>
                  Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Notes'
                  name='notes'
                  onChange={this.handleInteractionDetailFormChange}
                  value={interactionDetail.notes || ''}></textarea>
              </div>

              <div className='form-group w-50'>
                <label className='form_label' htmlFor='followup_date'>
                  Followup Date
                </label>
                <input
                  className='form-control'
                  placeholder='Followup Date'
                  name='followup_date'
                  type='date'
                  onChange={this.handleInteractionDetailFormChange}
                  value={interactionDetail.followup_date || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='next_step'>
                  Next Step
                </label>
                <input
                  className='form-control'
                  placeholder='Next Step'
                  name='next_step'
                  type='text'
                  onChange={this.handleInteractionDetailFormChange}
                  value={interactionDetail.next_step || ''}></input>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleInteractionSaveButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>
            {!this.props.isNewInteraction ? (
              <div
                onClick={this.handleInteractionDeleteButton}
                className='small btn btn-danger'>
                Delete this Interaction
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
