export const userProfile = {
  display_name: '',
  mobile_phone: '',
  email_address: '',
  address: '',
  approved_user: false,
  role_level: 'edit',
  profile_complete: false,
  uid: '',
  email_verified: '',
};

/* export const userProfile = {
    display_name: user.displayName,
    mobile_phone: user.phoneNumber,
    email_address: user.email,
    address: '',
    approved_user: false,
    role_level: 'view',
    profile_complete: false,
    uid: user.uid,
    num_biz: 3,
    email_verified: user.emailVerified,
  };
*/
