import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import SlidingPane from 'react-sliding-pane';
import ContactDetailForm from './ContactDetailForm';

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isContactDetailFormOpen: false,
      contactsList: this.props.contactsList,
      filteredContactsList: [],
      isReferrerColHidden: true,
    };
  }

  handleAddNewContactButton = () => {
    this.setState({
      isNewContact: true,
      isContactDetailFormOpen: true,
    });
  };

  handleContactRowClicked = (contactId) => {
    this.props.onContactRowClicked(contactId);
  };

  componentDidMount() {}

  handleContactSaveDeleteButtonClick = (value) => {
    this.setState({ isContactDetailFormOpen: false });
  };

  render() {
    let contactsList = this.props.contactsList || [];

    const contactsFilteredByCurrentUser = contactsList.filter((contactItem) =>
      contactItem.referrer_id.includes(this.props.currentUser.id)
    );

    const columns = [
      {
        dataField: 'referrer_name',
        text: 'Referrer',
        sort: true,
        hidden: this.state.isReferrerColHidden,
      },
      {
        dataField: 'contact_name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'id',
        text: 'Contact ID',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'first_name',
        text: 'First Name',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'last_name',
        text: 'Last Name',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'phone_number',
        text: 'Phone',
        sort: true,
      },
      {
        dataField: 'num_biz',
        text: 'Num Biz',
        sort: true,
        hidden: true,
      },

      {
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'follow_up_date',
        text: 'Follow Up',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'next_step',
        text: 'Next Step',
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'review_date',
        order: 'asc',
      },
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.handleContactRowClicked(row.id);
        /* this.setState({
          selectedContactDetail: row,
          isNewContact: false,
          active: true,
        });  */
      },
      onMouseEnter: (e, row, rowIndex) => {
        //console.log(`enter on row with index: ${rowIndex}`);
      },
    };

    return (
      <div>
        <div className='d-flex'>
          <h3>My Contacts</h3>
          <div
            onClick={() => this.handleAddNewContactButton()}
            className='btn btn-outline-dark ml-auto mb-2'>
            <span>Add New Contact</span>
          </div>
        </div>
        <BootstrapTable
          noDataIndication='There are no contacts to display here at the moment'
          classes='auto-table-layout'
          keyField='id'
          data={contactsFilteredByCurrentUser}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
        <hr />
        <SlidingPane
          className='contact-detail-pane'
          overlayClassName='pane-contact-sliding-pane'
          isOpen={this.state.isContactDetailFormOpen}
          title='Contact Detail'
          subtitle=''
          width='85%'
          from='right'
          onRequestClose={() => {
            this.setState({ isContactDetailFormOpen: false });
          }}>
          <ContactDetailForm
            referrerProfile={this.props.currentUser}
            profilesList={this.props.profilesList}
            selectedContactDetail={this.state.selectedContactDetail}
            isNewContact={this.state.isNewContact}
            onContactSaveDeleteButtonClicked={() =>
              this.handleContactSaveDeleteButtonClick()
            }
          />
        </SlidingPane>
      </div>
    );
  }
}

export default Contacts;
