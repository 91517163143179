import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../images/qoin-referrals-crm-logo.png';

import { auth } from '../../services/firebase';

class Header extends Component {
  async componentDidMount() {}

  render() {
    return (
      <header>
        <Navbar
          className='header w-100'
          collapseOnSelect
          expand='lg'
          variant='dark'>
          <Navbar.Brand href='/dashboard'>
            <img className='logo-image' src={logo} alt='Qoin CRM Logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='ml-auto'>
              {auth().currentUser ? (
                <div className='navbar-nav'>
                  <Nav.Link
                    className='nav-item btn btn-outline-secondary mr-3'
                    href='/dashboard'>
                    Dashboard
                  </Nav.Link>
                  <Nav.Link
                    className='nav-item btn btn-outline-secondary mr-3'
                    href='/'
                    onClick={() => auth().signOut()}>
                    Logout
                  </Nav.Link>
                </div>
              ) : (
                <div className='navbar-nav'>
                  <Nav.Link className='nav-item nav-link mr-3' href='/login'>
                    Sign In
                  </Nav.Link>
                  <Nav.Link className='nav-item nav-link mr-3' href='/signup'>
                    Sign Up
                  </Nav.Link>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}

export default Header;
