import React, { Component } from 'react';

class Loading extends Component {
  async componentDidMount() {}

  render() {
    return (
      <div
        className='spinner-border text-success loading-indicator'
        role='status'>
        <span className='sr-only'> Dashboard Loading...</span>
      </div>
    );
  }
}

export default Loading;
