import React, { Component } from 'react';
import { auth, rtDB } from '../../services/firebase';

export default class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDetail: this.props.profileDetail,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentDidMount() {}

  async componentWillUnmount() {
    let profileDetail = this.state.profileDetail;

    if (profileDetail.display_name && profileDetail.mobile_phone) {
      profileDetail.profile_complete = true;
    } else {
      profileDetail.profile_complete = false;
    }

    rtDB.ref('profiles/' + this.props.currentUser.id).update(profileDetail);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let profileDetail = { ...this.state.profileDetail };
    profileDetail[name] = value;

    this.setState({ profileDetail });
  };

  render() {
    const profileDetail = this.state.profileDetail;

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {auth().currentUser.uid === profileDetail.uid ||
          profileDetail.role_level === 'admin' ? (
            <React.Fragment>
              <div className='small'>
                Edit your details below. Changes will be made automatically.
                Close out of this panel when finished
              </div>
              <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
                <div className='form-group'>
                  <label className='form_label' htmlFor='display_name'>
                    Display Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Display Name'
                    name='display_name'
                    type='text'
                    onChange={this.handleInputChange}
                    value={profileDetail.display_name || ''}></input>
                </div>
                <div className='form-group'>
                  <label className='form_label' htmlFor='mobile_phone'>
                    Mobile Phone
                  </label>
                  <input
                    className='form-control'
                    placeholder='Mobile Phone'
                    name='mobile_phone'
                    type='text'
                    onChange={this.handleInputChange}
                    value={profileDetail.mobile_phone || ''}></input>
                </div>

                {profileDetail.role_level === 'admin' ? (
                  <React.Fragment>
                    <div className='form-group'>
                      <label className='form_label' htmlFor='role_level'>
                        Role Level
                      </label>
                      <select
                        className='form-control'
                        placeholder='Role Level'
                        name='role_level'
                        onChange={this.handleInputChange}
                        value={profileDetail.role_level || ''}>
                        <option value='admin'>Admin</option>
                        <option value='admin'>Agent</option>
                        <option value='edit'>Edit</option>
                        <option value='view'>View</option>
                      </select>
                    </div>
                    <div className='form-group'>
                      <label className='form_label' htmlFor='approved_user'>
                        Approved User
                      </label>
                      <select
                        className='form-control'
                        placeholder='Approved User'
                        name='approved_user'
                        onChange={this.handleInputChange}
                        value={profileDetail.approved_user}>
                        <option value='0'>No</option>
                        <option value='1'>Yes</option>
                      </select>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>Profile Role: {profileDetail.role_level}</div>
                  </React.Fragment>
                )}

                <div className='form-group'>
                  {this.state.error ? (
                    <p className='text-danger'>{this.state.error}</p>
                  ) : null}
                </div>
              </form>
            </React.Fragment>
          ) : (
            <div className='mt-1 py-3 px-3'>
              <div>{profileDetail.display_name}</div>
              <div>{profileDetail.mobile_phone}</div>
              <div>{profileDetail.email_address}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
